import styled, {keyframes} from 'styled-components';

interface Props{
    primary: boolean;
}

const Animation = keyframes`
  0% {
    transform: scale(0,0);
  }

  100% {
    transform: scale(1,1);
  }
`;

const modal = styled.div`
  position: fixed;
  padding: 10px;
  background: black;
  border-radius: 10px;
  z-index: 2;
  margin: auto;
  animation: ${Animation} 0.2s linear;
`;

export default modal;