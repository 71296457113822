import {useState} from 'react';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Text from '../../components/Text';
import Row from '../../components/Row';
import Input from '../../components/Input';
import Blur from '../../components/blur';
import Modal from '../../components/modal';
import Popup from '../../components/popup';
import axios from 'axios';
//const emailjs = require('emailjs-com');

const Home = (Props: any) => {
    const [modal, setModal] = useState(false);
    const [PopUp, setPop] = useState(true);
    const [email, setEmail] = useState('');
    const [showEmail, setShowEmail] = useState(true);
    const [ShowExit, setShowExit] = useState(false);
    //emailjs.init("user_TLqcWTkUHQBq6ba5JzMKS");

    const Send = () => {
        setShowEmail(false)
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test((email).toLowerCase())){
            setModal(!modal);
        }else{
            axios.post('https://senitembe.me/api/email',{email: email})
            .then(function (response) {
                setShowExit(true);
                setTimeout(() => setShowExit(false), 2000);
                setTimeout(() => {window.location.href="https://frmz-a.com"}, 3500);
            })
            .catch(function (error) {
                setModal(false);
            });
        }
    }

    const Email = (event: any) => {
        setEmail(event.target.value);
    }

    const updateModal = () => {
        setModal(!modal);
        setShowEmail(true);
    }

    return (
        <Container primary>
            {
                showEmail?
                <>
                    <Row>
                        <Text>EMAIL:</Text><Input onChange={Email}/>
                    </Row>
                    <Button onClick={Send}>SEND</Button>
                </>
                : 
                null
            }
            
            {
                modal ?
                <>
                <Blur />
                <Modal>
                    <Text>Error Sending email. Please Try again</Text>
                    <Row>
                        <Button onClick={updateModal}>TRY AGAIN</Button>
                    </Row>
                </Modal>
                </>
                :
                null
            }

            {
                ShowExit ?
                <Container primary> 
                {
                    PopUp ?
                        <Popup>
                            <Text>YES THIS IS A CULT.</Text>
                        </Popup>
                    :
                    null
                }
                </Container>
                :
                null
            }
        </Container>
    );
}

export default Home;
