import {useState} from 'react';
import Container from '../../components/Container';
import Button from './../../components/Button';
import Text from '../../components/Text';
import Logo from '../../components/Logo';
import Popup from '../../components/popup';
import Swipe from '../../components/swipe';
import Row from '../../components/Row';
import Blur from '../../components/blur';
import Modal from '../../components/modal';
import Block from '../../components/logoblock';

const Home = (Props: any) => {
    const [PopUp, setPop] = useState(true);
    const [modal, setModal] = useState(false);
    const [buttonText, setButtonText] = useState("ENTER");
    const [button, setButton] = useState(false);
    const [row, setRow] = useState(true);
    const [opacity, setOpacity] = useState(1);
    const [text] = useState(["YOUʼVE BEEN SELECTED TO ATTEND THE FUNERAL OF MY YOUTH", "PLEASE SELECT THE MOST SUITED ANSWER", "FRM.Z-A CANʼT BEHELD RESPONSIBLE FOR ANY HARM OR INJURIES OCCURED", "DO YOU AGREE TO DESTROY ALL BELIEFS FROM YOUR PREVIOUS LIFE", "DO YOU AGREE TO ONLY WEAR BLACK?", "WILL YOU TAKE UNAPPEALING BATHROOM SELFIES?", "KYLIE JENNER?", "ARE YOU UNAPOLOGETICALLY SELFISH?", "PURESEX?", "DO YOU AGREE TO GET BRANDED?", "CONGRATULATIONS YOUR APPLICATION HAS BEEN SUCCESSFUL. PLEASE ENTER YOUR DETAILS"]);
    const [textNumber, setNumber] = useState(0)
    
    const Pressed = (decision: string) => {    
        if(textNumber>0){
            setButton(true);
            if(textNumber == 6 && decision === "agree"){
                setModal(!modal);
            }
            else if(decision === "disagree" && textNumber != 6){
                setModal(!modal);
            }
            else{
                setButtonText("AGREE")
                setTimeout(() => setRow(false), 100);
                setTimeout(() => setPop(true), 100);
                setTimeout(() => setPop(false), 1000);
                setTimeout(() => setRow(true), 1000);
                setNumber(textNumber+1);
            }            
        }else{
            setButtonText("PROCEED")
            setTimeout(() => setPop(true), 100);
            setPop(!PopUp);
            setNumber(textNumber+1);
        }
        if(textNumber==9){
            setButtonText("PROCEED");
            setButton(false);
        }
        if(textNumber==10){
            Props.nextStep();
        }
    }

    const updateModal = () => {
        setModal(!modal);
    }

    return (
        <Container primary={true}>
            <Block>
            {
                PopUp ?
                    <Popup>
                        <Logo/>
                    </Popup>
                :
                null
            }
            </Block>
                <Text>{text[textNumber]}</Text>
            <>
                <Row>
                <Button onClick={() => Pressed('agree')}>{buttonText}</Button> 
                {
                    button ?
                        <Button onClick={() => Pressed('disagree')}>DISAGREE</Button>
                        :
                        null
                }
                </Row>
                
            </>

            {
                modal ?
                <>
                <Blur />
                <Modal>
                    <Row>
                        <Text>Sorry!!. Please try again</Text>
                    </Row>
                    <Row>
                        <Button onClick={updateModal}>TRY AGAIN</Button>
                    </Row>
                </Modal>
                </>
                :
                null
            }
        </Container>
    );
}

export default Home;
