import Styled from 'styled-components';

const blur = Styled.div`
  position fixed;
  min-width: 100vw;
  height: 100vh;
  top: 0%;
  z-index: 1;
  background: rgba(200,2,0,0.8);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
`;

export default blur;