import styled from 'styled-components';

interface Props{
    image: string;
}

const View = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:100;
  mix-blend-mode: difference;
`;

export default View;