import styled from 'styled-components';

interface Props{
    primary: boolean;
}

const View = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p:Props) => p.primary ? "black" : "white"};
  z-index:99;
`;

export default View;