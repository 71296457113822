import styled from 'styled-components';

const Input = styled.input`
  background: none;
  padding 0px;
  margin: 0px auto;
  border: none;
  color: #fabe05;
  font-size: 21px;
  border-bottom: 3px solid #fabe05;
  outline: none;
  font-weight: bold;
  margin-left: 10px;
`;

export default Input;

