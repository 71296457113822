import StepWizard from "react-step-wizard";
import StepOne from './invite/stepOne';
import StepTwo from './invite/stepTwo';
import StepThree from './invite/stepThree';
import StepFour from './invite/stepFour';
import StepFive from './invite/stepFive';
import { Helmet } from "react-helmet";

const Invite = (Props: any) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FRMZ-A: Invite</title>
                <link rel="canonical" href="https://frmz-a.com/invite" />
            </Helmet>
            <StepWizard>
                <StepOne />
                <StepFour />
                <StepFive />
            </StepWizard>
        </>
    );
}

export default Invite;
