import { Switch } from "react-router";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/home';
import Invite from './pages/invite';

const App = () => {
  return(
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/invite">
            <Invite />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
