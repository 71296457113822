import styled, { keyframes } from 'styled-components';

const Button = styled.button`
  background-color: #fabe05;
  border: none;
  color: black;
  outline: none;
  font-family: light;
  font-size: 21px;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;

  &:active {
    color: #fabe05;
    background: black;
  }  
`;

export default Button;
