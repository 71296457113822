import Styled, {keyframes} from 'styled-components';

const Animation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;
const Popup = Styled.div`
    animation: ${Animation} 1.5s linear;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Popup;