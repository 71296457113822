import Container from '../../components/Container';
import Button from '../../components/Button';
import Text from '../../components/Text';

const Home = () => {
    return (
        <Container primary>
            <Text>YES THIS IS A CULT.</Text>
        </Container>
    );
}

export default Home;
