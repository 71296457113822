import View from './View';

function Block(Props: any) {
  return (
      <>
        <View primary={true}/>
        <View primary={false}/>
      </>
    )
}

export default Block;