import { motion, useMotionValue } from "framer-motion";

interface Props{
}

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "#fabe05"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "#fabe05"
  }
};

export default function Logo() {
  const pathLength = useMotionValue(0);
  const duration = 1;
  return (
      <motion.svg
        height="200"
        viewBox="0 0 256.92 617.1"
        xmlns="http://www.w3.org/2000/svg">
        {/*new row*/}
        <g opacity="1">
          <motion.path
            d="M191.93,148.6H15.1v30.21h98.25V299.73H0V481.07H226.71V617.1h30.21V450.86H30.21V329.93h211.6v-30.2H143.56V178.81H256.92V12.57H226.71V135.48L23.48,0,6.73,25.13Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            fill="#fabe05"
            strokeWidth="2"
            stroke="#fabe05"
          />
        </g>
      </motion.svg>
  );
}
