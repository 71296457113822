import Styled from 'styled-components';

interface Props{
  primary: boolean
}

const Container = Styled.div`
  display: flex;
  height: 100vh;
  min-width: 100%;
  margn:0px auto;
  padding: 0px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(p:Props) => p.primary? 'black': 'white'};
  font-size: 22px;
  z-index: 0;
`;

export default Container;
