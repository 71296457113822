import react, {useState, useEffect} from 'react' 
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Block from './../components/Block';
import Floating from './../components/Floating';
import Button from './../components/Button';
import Life from './../images/lifestyle.svg';
import Black from '../images/black.svg';
import White from '../images/white.svg';
import Test from '../components/Test'

const FloatingBlock = styled(Button)`
  border: none;
  outline: none;
  background: none;
  width: 80vw;
  display: flex;
  align-item: center;
  justify-content: center;
  background: none;
  &:hover{
    background: none;
  }
`;

function App() {
    const [scroll, setScroll] = useState([0])
    
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight){
        let empty = scroll
        empty.push(scroll.length+1)
        setScroll([...empty])
      }else{ 
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FRMZ-A</title>
          <link rel="canonical" href="https://frmz-a.com/" />
        </Helmet>
        <Floating>
          <FloatingBlock>
            <img src={Life} width={'100%'}/>
          </FloatingBlock>
        </Floating>
        <Test>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="256.92"
            height="617.1"
            className="light-svg"
            viewBox="0 0 256.92 617.1">
            <path
              fill="white" 
              d="M191.93,148.6H15.1v30.21h98.25V299.73H0V481.07H226.71V617.1h30.21V450.86H30.21V329.93h211.6v-30.2H143.56V178.81H256.92V12.57H226.71V135.48L23.48,0,6.73,25.13Z"/>
          </svg>
          
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="256.92"
            height="617.1"
            className="dark-svg"
            viewBox="0 0 256.92 617.1">
            <path
              fill="black" 
              d="M191.93,148.6H15.1v30.21h98.25V299.73H0V481.07H226.71V617.1h30.21V450.86H30.21V329.93h211.6v-30.2H143.56V178.81H256.92V12.57H226.71V135.48L23.48,0,6.73,25.13Z"/>
          </svg>
        </Test>

        {scroll.map(item => (
            <Block key={item}/>
        ))}
      </>
    );
}

export default App;